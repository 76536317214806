import React from 'react'
import {
  HeroLeftSide,
  SignUpForm,
  ReviewSection,
  MemberShipSection,
} from '../additional'

const LIST_FAST_FACTS = [
  '50% of members received a job offer',
  '85% had an interview',
  '90% would recommend MedReps',
]

const CapitalEquipmentSalesComp = ({ user }) => {
  return (
    <div>
      {!user && (
        <div className="container mx-auto mt-10">
          <div className="max-w-[960px] mx-auto flex gap-[55px] p-5 flex-col md:flex-row">
            <HeroLeftSide
              title="Looking for capital equipment sales jobs?"
              desc="Our platform showcases job listings from the industry's leading companies. Whether you're an experienced sales professional or just starting out, we have a wide range of opportunities waiting for you."
              listTitle="Fast facts:"
              listOption={LIST_FAST_FACTS}
            />
            <SignUpForm />
          </div>
        </div>
      )}
      <MemberShipSection />
      <ReviewSection />
    </div>
  )
}

export default CapitalEquipmentSalesComp
