import React from 'react'
import { MinusIcon, PlusIcon, StepCheckIcon } from '../../icons'

const HeroLeftSide = ({ title, desc, listTitle, listOption }) => {
  return (
    <div className="bg-[#FAF4F6] rounded-3xl flex-1 px-[25px] py-[30px]">
      <h1 className="text-4xl font-normal	text-black font-playfair mb-4">
        {title}
      </h1>
      <p className="text-lg mb-8 font-normal font-sans text-black">{desc}</p>
      <div>
        <h1 className="text-4xl font-normal font-playfair text-black mb-4">
          {listTitle}
        </h1>
        {listOption.map(fastFact => (
          <div className="flex items-center">
            <div className="bg-[#6D1E36] w-[18px] h-[18px] flex justify-center items-center rounded-full mr-4">
              <StepCheckIcon />
            </div>
            <span className="text-black font-sans text-lg">{fastFact}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default HeroLeftSide
