import React, { useEffect, useState } from 'react'
import { MinusIcon, PlusIcon, StepCheckIcon } from '../../icons'
import { Form, message, Modal, Input, Checkbox } from 'antd'
import { auth0Roles, strapiURL } from '../../../config'
import axios from 'axios'
import cookie from 'react-cookies'
import { useDispatch } from 'react-redux'
import { setProfileData } from '../../../redux/actions/user'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { Link } from 'gatsby'
import GlobalData from '../../../../globalData.json'

const navbarItems = [
  {
    label: 'Select Plan',
    id: 1,
  },
  {
    label: 'Personal Info',
    id: 2,
  },
  {
    label: 'Make Payment',
    id: 3,
  },
]

const SignUpForm = () => {
  const [step, setStep] = useState(1)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [listingPrices, setListingPrices] = useState([])
  const [activePriceIndex, setActivePriceIndex] = useState(-1)
  const [signUpForm] = Form.useForm()

  const dispatch = useDispatch()

  useEffect(() => {
    let _prices = GlobalData?.CandidateCatalog?.prices?.length
      ? GlobalData?.CandidateCatalog?.prices?.filter(
          item => !item.isDiscontinued
        )
      : []
    setListingPrices(_prices)
  }, [])

  const handleNext = () => {
    if (step < 3) {
      setStep(step + 1)
    }
  }

  const signUp = values => {
    const regex =
      /^((?=.*[A-Z])(?=.*[a-z])(?=.*\d)|(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])|(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])|(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])).{8,16}$/
    if (values.email !== values.confirmEmail) {
      message.error("Email didn't match.")
      return
    }
    if (values.password !== values.confirmPassword) {
      message.error("Password didn't match.")
      return
    }
    if (
      values.confirmPassword.length < 8 ||
      !values.confirmPassword.match(regex)
    ) {
      message.error(
        'Your password must contain at least 8 characters including at least 3 of the following 4 types of characters: a lower-case letter, an upper-case letter, a number, a special character (such as !@#$%^&*).'
      )
      return
    }
    setSubmitLoading(true)
    let data = {
      email: (values.email || '').toLowerCase(),
      password: values.password,
      last_login: new Date(),
      roleId: auth0Roles.Member.id,
      firstName: values.firstName,
      lastName: values.lastName,
      name: values.firstName + ' ' + values.lastName,
      is_newsletter_subscriber: values?.is_newsletter_subscriber || false,
    }

    axios
      .post(strapiURL + '/auth0/signup', data)
      .then(res => {
        const userData = res.data
        const responseRole = userData.role.id
        let profile = userData?.profile
        delete userData.profile

        cookie.save('userRole', responseRole, { path: '/', maxAge: 259200 })
        cookie.save('user', userData, { path: '/', maxAge: 259200 })

        dispatch(setProfileData(profile))

        const subscription = listingPrices[activePriceIndex]?.price?.priceId

        if (!subscription && typeof window !== 'undefined') {
          window.location.href = '/member-dashboard'
        } else {
          if (typeof window !== 'undefined') {
            let _checkoutPrices = []
            _checkoutPrices.push({
              price: subscription,
              quantity: 1,
            })
            localStorage.setItem('can-subscription', subscription)
            axios
              .post(strapiURL + '/stripe/session/checkout', {
                line_items: _checkoutPrices,
                success_url:
                  window.location.origin +
                  '/thankyou/?success=true&session_id={CHECKOUT_SESSION_ID}&first_registration=true',
                cancel_url: window.location.origin,
                customerEmail: (values.email || '').toLowerCase(),
                mode: 'subscription',
                clientId: cookie.load('_ga')?.substring(6),
                sessionId: cookie
                  .load(
                    process.env.GATSBY_GOOGLE_ANALYTICS_MEASUREMENT_ID?.replace(
                      'G-',
                      '_ga_'
                    )
                  )
                  ?.split('.')[2],
              })
              .then(res => {
                if (typeof window !== 'undefined') {
                  window.history.replaceState(
                    '',
                    '',
                    `${window.location.origin}/thankyou/`
                  )
                  window.location.href = res?.data?.url
                }
              })
              .catch(err => {
                message.error('Something wrong while checkout.')
              })
          }
        }
      })
      .catch(err => {
        message.error(err?.response?.data?.message[0].error)
        setSubmitLoading(false)
      })
  }

  return (
    <div className="flex-1">
      <div>
        <h4 className="text-[#6D1E36]">Member Sign Up</h4>
        <div className="navbar-area">
          <div className="flex items-center">
            {navbarItems?.map((navItem, navIndex) => {
              return (
                <>
                  <div className="membership-step" key={navIndex}>
                    {step >= navItem.id ? (
                      <div className="active h-4 w-4 bg-[#6D1E36] rounded-full flex items-center justify-center">
                        {step === navItem.id ? (
                          <div className="h-2 w-2 bg-white rounded-full" />
                        ) : (
                          <StepCheckIcon />
                        )}
                      </div>
                    ) : (
                      <div className="inactive bg-border h-4 w-4 bg-[#E1E1E1] rounded-full" />
                    )}

                    <div className="absolute">
                      <p className="w-full text-xs font-semibold font-sans w-max mt-4 text-black">
                        {navItem.label}
                      </p>
                    </div>
                  </div>
                  {navIndex !== navbarItems?.length - 1 && (
                    <div className="divider w-20 md:w-85 h-px bg-border" />
                  )}
                </>
              )
            })}
          </div>
        </div>
        {step === 1 && (
          <div className="mt-12">
            <h1 className="text-2xl font-normal text-black font-playfair">
              Choose your membership cycle
            </h1>
            <h2 className="text-xl font-normal font-sans text-black">
              Cancel anytime
            </h2>
            <div>
              {listingPrices.map((priceItem, priceIndex) => {
                return (
                  <div
                    className={`px-6 py-4 mb-4 flex justify-between items-center cursor-pointer shadow-md	rounded-xl ${
                      activePriceIndex === priceIndex
                        ? 'border-2 border-[#6D1E36]'
                        : ''
                    }`}
                    onClick={() => setActivePriceIndex(priceIndex)}
                  >
                    <div className="flex items-center">
                      <div
                        className={`w-[26px] h-[26px] mr-4 rounded-full flex justify-center items-center ${
                          activePriceIndex === priceIndex
                            ? 'bg-[#6D1E36]'
                            : 'bg-[#E1E1E1]'
                        }`}
                      >
                        {activePriceIndex === priceIndex && <StepCheckIcon />}
                      </div>
                      <div className="">
                        <h4 className="mb-0 text-xl font-bold text-black">
                          {priceItem.name}
                        </h4>
                        <p className="mb-0 text-sm text-black">
                          ({priceItem?.price?.red_desc})
                        </p>
                      </div>
                    </div>
                    <div
                      className={`text-xl font-bold ${
                        activePriceIndex === priceIndex
                          ? 'text-[#6D1E36]'
                          : 'text-black'
                      }`}
                    >
                      ${priceItem?.price?.amount}
                    </div>
                  </div>
                )
              })}
            </div>
            <button
              className="bg-voyage w-full py-4 text-white font-sans font-bold mb-0"
              onClick={handleNext}
              disabled={activePriceIndex < 0}
            >
              Next
            </button>
          </div>
        )}
        {step === 2 && (
          <Form
            className="pt-6 mt-12"
            form={signUpForm}
            layout="vertical"
            onFinish={signUp}
          >
            <div className="flex items-center justify-between flex-wrap">
              <div className="w-full md:w-48%">
                <Form.Item
                  name="firstName"
                  className="theme-form-item"
                  rules={[
                    {
                      required: true,
                      message: 'Required.',
                    },
                  ]}
                >
                  <Input
                    name="firstName"
                    type="text"
                    placeholder="First Name"
                    className="placeholder-charcoal focus:border-merlot focus:outline-none focus:shadow-none"
                  />
                </Form.Item>
              </div>
              <div className="w-full md:w-48%">
                <Form.Item
                  name="lastName"
                  className="theme-form-item"
                  rules={[
                    {
                      required: true,
                      message: 'Required.',
                    },
                  ]}
                >
                  <Input
                    name="lastName"
                    type="text"
                    placeholder="Last Name"
                    className="placeholder-charcoal focus:border-merlot focus:outline-none focus:shadow-none"
                  />
                </Form.Item>
              </div>
              <div className="w-full md:w-48%">
                <Form.Item
                  name="email"
                  className="theme-form-item"
                  rules={[
                    {
                      required: true,
                      message: 'Required.',
                    },
                  ]}
                >
                  <Input
                    name="email"
                    type="email"
                    placeholder="E-Mail"
                    className="placeholder-charcoal focus:border-merlot focus:outline-none focus:shadow-none"
                  />
                </Form.Item>
              </div>
              <div className="w-full md:w-48%">
                <Form.Item
                  name="confirmEmail"
                  className="theme-form-item"
                  rules={[
                    {
                      required: true,
                      message: 'Required.',
                    },
                  ]}
                >
                  <Input
                    name="confirmEmail"
                    type="email"
                    placeholder="Confirm E-Mail"
                    className="placeholder-charcoal focus:border-merlot focus:outline-none focus:shadow-none"
                  />
                </Form.Item>
              </div>
              <div className="w-full md:w-48%">
                <Form.Item
                  className="theme-form-item-password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Required.',
                    },
                  ]}
                >
                  <Input.Password
                    name="password"
                    placeholder="Password"
                    iconRender={visible =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
              </div>
              <div className="w-full md:w-48%">
                <Form.Item
                  className="theme-form-item-password"
                  name="confirmPassword"
                  rules={[
                    {
                      required: true,
                      message: 'Required.',
                    },
                  ]}
                >
                  <Input.Password
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    iconRender={visible =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <Form.Item
              className="theme-form-item mb-1"
              name="remember"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            'Please review the terms and conditions and check the box to proceed.'
                          )
                        ),
                },
              ]}
            >
              <div className="flex items-start">
                <span className="mr-[4px] text-xl text-[#ff4d4f]">*</span>
                <Checkbox name="remember" className="font-semibold font-sans">
                  I have read and agree to the MedReps{' '}
                  <a href="/privacy-policy" target="_blank">
                    Terms & Conditions
                  </a>
                </Checkbox>
              </div>
            </Form.Item>
            <p className="pt-0 font-sans text-last mb-4">
              We think it's a good idea to stay plugged into the job market, so
              unless you request otherwise, your membership will automatically
              renew.
            </p>
            <p className="pt-0 font-sans text-last mb-6">
              By joining Medreps.com you agree to receive communications from
              time to time to keep you informed of site updates as it pertains
              to your membership.
            </p>

            <Form.Item
              className="theme-form-item mb-1"
              name="is_newsletter_subscriber"
              valuePropName="checked"
            >
              <Checkbox name="subscribe" className="font-semibold font-sans">
                Subscribe to The Brief, MedReps' newsletter featuring latest
                medical sales career and industry insights
              </Checkbox>
            </Form.Item>
            <div className="mt-6">
              <button
                type="button"
                onClick={() => {
                  signUpForm.submit()
                }}
                className="bg-voyage py-2 px-6 text-white font-sans rounded font-semibold text-sn"
              >
                {submitLoading ? 'Loading...' : 'Register'}
              </button>
              <p className="font-sans text-sm font-semibold mb-0 mt-4">
                Want to post jobs?
              </p>
              <p className="font-sans font-normal text-last mb-0">
                Employers/Recruiters&nbsp;
                <Link
                  to="/employer/signup"
                  className="text-poppy underline font-semibold text-last"
                >
                  Click Here
                </Link>{' '}
              </p>
            </div>
          </Form>
        )}
      </div>
    </div>
  )
}

export default SignUpForm
