import React from 'react'
const LIST_MEMBERSHIP = [
  'Unlimited access to medical sales jobs from top employers.',
  'Medical sales salary information.',
  'A searchable profile visible to medical sales employers and recruiters.',
  'Medical sales career advice and news, available exclusively to MedReps members.',
]

const MemberShipSection = () => {
  return (
    <div className="bg-[#EFF5F8] pt-4 pb-10 mt-10">
      <div className="container mx-auto px-5">
        <h1 className="mb-6 text-5xl text-center font-playfair text-black">
          MedReps Membership:
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2">
          {LIST_MEMBERSHIP.map(membership => (
            <div className="flex items-center mb-2">
              <div className="bg-[#642436] min-w-[13px] h-[13px] rounded-full mr-6"></div>
              <p className="text-black font-sans text-xl mb-0">{membership}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default MemberShipSection
