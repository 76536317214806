import React from 'react'
import Wrapper from '../components/wrapper'
import JobSearchComponent from '../components/pageComponents/jobSearchComponent'
import cookie from 'react-cookies'
import CapitalEquipmentSalesComp from '../components/pageComponents/capitalEquipmentSales'

const CapitalEquipmentSalesPage = ({ location }) => {
  let user = cookie.load('user')
  let userRole = cookie.load('userRole')
  const Children = props => {
    return (
      <div>
        <CapitalEquipmentSalesComp {...props} />
        <JobSearchComponent
          {...props}
          user={user}
          location={props.location}
          isSearch={true}
          role={userRole}
          openSignupModal={props.openSignupModal}
          openLoginModal={props.openLoginModal}
          sourceMethod="sales"
          preProductCategory={'Capital_Equipment'}
          searchTitle="Capital Equipment Jobs"
        />
      </div>
    )
  }

  return (
    <Wrapper location={location} title="Capital Equipment Sales | MedReps.com">
      <Children />
    </Wrapper>
  )
}
export default CapitalEquipmentSalesPage
