import React from 'react'
const ReviewSection = () => {
  return (
    <div className="py-10 px-5">
      <div className="mx-auto max-w-[900px] flex gap-20 flex-col md:flex-row">
        <div className="relative flex-1">
          <div
            className="w-full max-w-[400px] h-[300px] bg-cover bg-top"
            style={{
              backgroundImage: `url(/images/hiring-man.png)`,
            }}
          ></div>

          <img
            src="/images/home-hero-vector-blue.webp"
            className="rotate-180 absolute bottom-[-75px] -left-12 "
          />
        </div>
        <div className="relative flex-1">
          <img src="/images/blue_quote.svg" className="mb-4"></img>
          <p className="text-3xl font-sans text-black mb-12 font-normal">
            I have been successful using MedReps in my last three job searches.
          </p>
          <h4 className="text-2xl font-sans text-black mb-0 font-bold">
            Anthony O| 2021
          </h4>
          <img
            src="/images/join-page-vector-red.svg"
            className="absolute top-[-75px] right-[-170px] hidden md:block"
          />
        </div>
      </div>
    </div>
  )
}

export default ReviewSection
